import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Trans, withI18n } from '@lingui/react';
import NavPanel from './NavPanel';
// import Transition from '../components/Transition';

// styles
import '../styles/style.scss';

class TemplateWrapper extends Component {
  constructor(props) {
    super(props);
    this._$refTemplateWrapper = null;
    this.state = {
      $refTemplateWrapper: null,
      isMenuOpen: false,
      decoratorCls: '',
      funcDecorator: (cls) => {
        this.setState({
          decoratorCls: cls,
        });
      },
    };
  }
  
  componentDidMount() {
    this.setState({
      $refTemplateWrapper: this._$refTemplateWrapper,
    });
  }

  render() {
    const { pathName, children, location } = this.props;
    const isLabPage = pathName.includes('lab') || pathName.includes('process');
    const lightCls = isLabPage ? 'page-is-light' : '';
    
    const {
      isMenuOpen, $refTemplateWrapper, funcDecorator, decoratorCls,
    } = this.state;

    const openCls = isMenuOpen ? 'menu-is-active' : '';
    return (
      <div
        ref={(_mount) => {
          this._$refTemplateWrapper = _mount;
        }}
        className={`main-wrapper ${openCls} ${lightCls} ${decoratorCls}`}
        >
        <TemplateWrapperContext.Provider
          value={{ $refTemplateWrapper, funcDecorator }}
        >
          <Helmet defaultTitle="Lumen Software" titleTemplate="%s - We Craft Beautiful Software">
            <meta name="og:type" content="website" />
            <meta name="og:site_name" content="Lumen Software" />
            <title>Lumen Software</title>
            <meta name="description" content="Lumen Software is a software development company based in Montreal, Canada.  We specialize in creating modern beautiful web and mobile apps." />
          </Helmet>
          <NavPanel
            {...this.props}
            onToggle={isOpen => this.setState({ isMenuOpen: isOpen })}
            defaultIsOpen={isMenuOpen}
          />
          <main className="main-content">
            { children }
          </main>
          {pathName !== '/fr/' && pathName !== '/' && pathName !== '/404/' && pathName !== '/fr/404/' && location.key
            && (
              <footer className="main-footer">
                <Trans>
                  Copyright© 2021 Lumen Software. All right reserved.
                  <br />
                  3 Place Ville Marie, Suite 400, Montréal, QC
                </Trans>
              </footer>
            )
          }
        </TemplateWrapperContext.Provider>
      </div>
    );
  }
}

export default withI18n()(TemplateWrapper);
export const TemplateWrapperContext = React.createContext();
