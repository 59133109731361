import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Trans, withI18n } from '@lingui/react';
import { prefix } from '../i18n-config';

// images
import menuScreenUrl from './img/menu-screen.svg';

// images
// convert from svg to react with svgr
import LogoLumen from './img/LogoLumen';

// styles
const menuScreenStyle = {
  backgroundImage: `url(${menuScreenUrl})`,
};

function LanguageSwitcher(props) {
  const { languages, currentLang, location } = props;
  const languagesList = languages.map((language) => {
    if (currentLang === language) {
      return false;
    }
    if (language === 'en') {
      return (
        <li key={language} className="languages-switcher__item">
          <Link key={language} to={location.pathname.substr(3)} className="languages-switcher__link">
            {language}
          </Link>
        </li>
      );
    }
    return (
      <li key={language} className="languages-switcher__item">
        <Link key={language} to={`/${language}${location.pathname}`}>
          {language}
        </Link>
      </li>
    );
  });

  return (
    <div className="main-header__languages">
      <ul className="languages-switcher">
        <li className="languages-switcher__item">
          <span className="languages-switcher__link active">
            {currentLang}
            <ul>{languagesList}</ul>
          </span>
        </li>
      </ul>
    </div>
  );
}

class NavPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.defaultIsOpen || false,
      liHover: '',
      hasShapePos: false,
      hasScrolled: true,
      scrollY: 0,
    };
    this.shipRef = React.createRef();
    this.shapesRef = []; // store all the svg shapes needed later
    this.shapesPosArray = [];
    this.shipRefPos = '';
    this.handleToggleClick = this.handleToggleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.shapesPosArray = this.shapesRef.map(element => element);
    this.shipRefPos = this.shipRef.current.getBoundingClientRect();
    const { hasScrolled } = this.state;
    window.addEventListener('scroll', this.handleScroll);
    setInterval(() => {
      if (hasScrolled) {
        this.fixNavOnScroll();
      }
    }, 150);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleToggleClick(isForceOpen = null) {
    const { onToggle } = this.props;
    let { isOpen } = this.state;

    isOpen = isForceOpen === true || isForceOpen === false ? isForceOpen : !isOpen;

    onToggle(isOpen);

    this.setState({ isOpen }, () => {
      const { hasShapePos } = this.state;
      // store svg shape informations only one time when openin
      if (hasShapePos === false && isOpen === true) {
        this.shapesPosArray = this.shapesPosArray.map((element) => {
          const shapeObj = {
            id: element.id,
            pos: element.getBoundingClientRect(),
          };
          return shapeObj;
        });
        this.setState({ hasShapePos: true });
      }
    });
  }

  handleEnter(e) {
    const target = e.target.nodeName === 'A'
      ? e.target.dataset.target
      : e.target.parentNode.parentNode.dataset.target;
    const shapeEl = document.getElementById(target);
    if (shapeEl != null && e.target.nodeName === 'A') {
      const shapeCel = this.shapesPosArray.find(o => o.id === target);
      const shapePos = shapeCel.pos;
      const translateX = this.shipRefPos.x
        + this.shipRefPos.width / 2
        - (shapePos.x + shapePos.width / 2);
      const translateY = this.shipRefPos.y
        + this.shipRefPos.height / 2
        - (shapePos.y + shapePos.height / 2);
      this.shipRef.current.setAttribute(
        'style',
        `transform: scale(2) translateX(${translateX}px) translateY(${translateY}px)`
      );
    }
    this.setState({
      liHover: target,
    });
  }

  handleLeave() {
    this.shipRef.current.setAttribute('style', 'transform: scale(1);');
    this.setState({
      liHover: '',
    });
  }

  handleScroll() {
    this.setState({
      hasScrolled: true,
    });
  }

  fixNavOnScroll() {
    const { scrollY, isOpen } = this.state;
    const currentPos = window.scrollY;
    if (Math.abs(scrollY - currentPos) <= 20) {
      return;
    }
    if (scrollY > 0 && currentPos > scrollY && isOpen === false) {
      // Scroll Down
      this.setState({ navUp: true });
    } else {
      // Scroll Up
      this.setState({ navUp: false });
    }
    if (currentPos > 150) {
      this.setState({ headerBg: true });
    } else {
      this.setState({ headerBg: false });
    }
    this.setState({
      hasScrolled: false,
      scrollY: currentPos,
    });
  }

  render() {
    const {
      lang, location, pathName, languages,
    } = this.props;
    const { navUp, liHover, headerBg } = this.state;
    const langPrefix = prefix(lang);

    return (
      <header
        className={`main-header ${
          navUp ? 'main-header--hidden' : 'main-header--visible'
        } ${headerBg ? 'main-header--bg' : ''}`}
      >
        <Link
          to={`${langPrefix}/`}
          className="main-header__logo-wrapper"
          onClick={() => {
            this.handleToggleClick(false);
          }}
        >
          <LogoLumen className="main-header__logo" />
        </Link>

        <LanguageSwitcher
          languages={languages}
          currentLang={lang}
          location={location}
        />

        <div className="main-menu">
          <button
            type="button"
            className="main-menu__toggle"
            onClick={() => {
              this.handleToggleClick();
            }}
          >
            <span className="main-menu__icon" />
          </button>
          <div className="main-menu__inner">
            <div className="main-menu__screen-container">
              <div className="main-menu__ship-container">
                <svg
                  className="main-menu__ship"
                  width={212.333}
                  height={159.667}
                  viewBox="0 0 212.333 159.667"
                  ref={this.shipRef}
                >
                  <g
                    id="contact"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    ref={el => this.shapesRef.push(el)}
                  >
                    <Link
                      to={`${langPrefix}/contact`}
                      onMouseEnter={e => this.handleEnter(e)}
                      onMouseLeave={e => this.handleLeave(e)}
                      data-target="contact"
                      className={`${liHover === 'contact' ? 'active' : ''} ${
                        pathName.includes('contact') ? 'active' : ''
                      }`}
                      onClick={() => {
                        this.handleToggleClick();
                      }}
                    >
                      <g id="contact-normal" stroke="#FDFAF6">
                        <path d="M172.145 112.115l9.973-19.574-13.295-12.398-14.524 28.501zM151.155 115.593l-.067.183-.022-.006-.017.105-10.239 27.395-5.777 2.834 9.291 4.447 3.299-1.619.436 2.51 9.061 4.905-1.106-6.341 13.162-31.075-8.31-1.448zM187.802 42.537l1.104 6.34-16.302 24.625-.004.003.001.001-.007.009.364.338 12.844 12.446 17.403-28.913 5.78-2.833-9.292-4.446-2.183 1.07-.649-3.736z" />
                      </g>
                      <g id="contact-hover" stroke="#50E3C2">
                        <path d="M172.254 112.18l9.975-19.575-13.295-12.397-14.524 28.5zM151.266 115.657l-.067.183-.022-.005-.015.104-10.242 27.395-5.777 2.834 9.292 4.449 3.299-1.62.436 2.51 9.061 4.904-1.106-6.34 13.162-31.075-8.31-1.449zM187.913 42.601l1.103 6.34-16.301 24.627-.005.003.001.001-.005.008.364.338 12.843 12.446 17.404-28.913 5.779-2.832-9.293-4.447-2.182 1.07-.65-3.737z" />
                      </g>
                    </Link>
                  </g>
                  <g
                    id="process"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    ref={el => this.shapesRef.push(el)}
                  >
                    <Link
                      to={`${langPrefix}/process`}
                      data-target="process"
                      onMouseEnter={e => this.handleEnter(e)}
                      onMouseLeave={e => this.handleLeave(e)}
                      onClick={() => {
                        this.handleToggleClick();
                      }}
                      className={`${liHover === 'process' ? 'active' : ''} ${
                        pathName.includes('process') ? 'active' : ''
                      }`}
                    >
                      <path
                        id="process-normal"
                        stroke="#FDFAF6"
                        d="M161.396 70.227l.007-.012 1.642 6.693-.006.014-14.516 28.49-.006.013-6.616 3.065.007-.014-.013.025-.007.014-34.926-17.796.007-.012.006-.012-7.474-9.955.007-.014 14.516-28.489.008-.013 12.445.195.006-.01.007-.013 34.926 17.794-.007.013"
                      />
                      <g id="process-hover" stroke="#50E3C2">
                        <path d="M153.649 95.353l-5.126 10.059-.006.013-6.616 3.065.007-.014-.013.025-.007.012-34.925-17.794.006-.012.006-.012-7.473-9.954.006-.015 5.243-10.288" />
                        <path d="M117.082 89.178l3.957 2.016 3.302-6.482-1.673-5.144-17.918-9.129-5.245 10.294 7.475 9.956-.013.024 7.408 3.774zM153.649 95.354l-19.92-10.15-5.146 1.671-3.301 6.481 3.957 2.016-2.706 5.311 15.36 7.826.012-.025 6.615-3.066 5.129-10.064" />
                      </g>
                    </Link>
                  </g>
                  <g id="lab" ref={el => this.shapesRef.push(el)}>
                    <Link
                      to={`${langPrefix}/lab`}
                      data-target="lab"
                      onMouseEnter={e => this.handleEnter(e)}
                      onMouseLeave={e => this.handleLeave(e)}
                      onClick={() => {
                        this.handleToggleClick();
                      }}
                      className={`${liHover === 'lab' ? 'active' : ''} ${
                        pathName.includes('lab') ? 'active' : ''
                      }`}
                    >
                      <g
                        id="lab-hover"
                        fill="none"
                        stroke="#50E3C2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit={10}
                      >
                        <path d="M143.323 67.729l-12.158-6.195 2.729-5.358-7.405-3.773-.014.024-12.448-.194-5.243 10.291 48.897 24.914 5.361-10.522-1.638-6.695.009-.023-15.36-7.826z" />
                        <path d="M108.784 62.524l5.241-10.286.007-.014 12.446.195.006-.011.006-.012 34.926 17.794-.007.012M161.396 70.227l.007-.013 1.643 6.695-.006.014-5.359 10.515" />
                      </g>
                    </Link>
                  </g>
                  <g
                    id="services"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    ref={el => this.shapesRef.push(el)}
                  >
                    <Link
                      to={`${langPrefix}/services`}
                      data-target="services"
                      onMouseEnter={e => this.handleEnter(e)}
                      onMouseLeave={e => this.handleLeave(e)}
                      onClick={() => {
                        this.handleToggleClick();
                      }}
                      className={`${liHover === 'services' ? 'active' : ''} ${
                        pathName.includes('services') ? 'active' : ''
                      }`}
                    >
                      <path
                        id="services-normal"
                        stroke="#FDFAF6"
                        d="M108.22 49.279l.005-.013L86.35 38.122l-3.656-.206-13.182 25.87.012.013-.004.006 2.315 2.838 21.874 11.145 14.523-28.502z"
                      />
                      <g id="services-hover" stroke="#50E3C2">
                        <path d="M108.235 49.248l.007-.013L86.367 38.09l-3.658-.206-13.181 25.87.011.014-.005.005 2.318 2.839 21.875 11.144 14.52-28.502z" />
                        <path d="M86.386 38.067L71.864 66.569l-2.316-2.836L82.73 37.861zM80.905 71.165l-2.727-12.514 5.318-10.437 11.917-5.523 12.849 6.522-14.523 28.502z" />
                        <path d="M85.5 70.384l-2.283-10.351 4.393-8.621 9.873-4.547 9.514 4.827L95 75.235zM80.905 71.165l4.489-.801M78.178 58.651l5.039 1.382M83.496 48.214l4.114 3.198M97.483 46.865l-2.07-4.174" />
                      </g>
                    </Link>
                  </g>
                  <g
                    id="expertise"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    ref={el => this.shapesRef.push(el)}
                  >
                    <Link
                      to={`${langPrefix}/expertise`}
                      data-target="expertise"
                      onMouseEnter={e => this.handleEnter(e)}
                      onMouseLeave={e => this.handleLeave(e)}
                      onClick={() => {
                        this.handleToggleClick();
                      }}
                      className={`${liHover === 'expertise' ? 'active' : ''} ${
                        pathName.includes('expertise') ? 'active' : ''
                      }`}
                    >
                      <path
                        id="expertise-normal"
                        stroke="#FDFAF6"
                        d="M63.559 60.762l.025-.052L76.74 34.889l.026-.048-1.943-.989 3.399-6.674c-7.292-3.715-11.522-3.498-15.089.717l-3.622-1.846-.024.049-13.159 25.821-.022.05 3.625 1.849c-1.304 5.353 1.011 8.898 8.296 12.61l3.39-6.657 1.942.991z"
                      />
                      <g id="expertise-hover" stroke="#50E3C2">
                        <path d="M63.565 60.75l.026-.051 13.154-25.822.026-.048-1.942-.989 3.398-6.674c-7.292-3.715-11.521-3.497-15.089.717l-3.623-1.847-.023.05-13.158 25.822-.023.05 3.626 1.85c-1.305 5.352 1.01 8.897 8.295 12.609l3.392-6.656 1.941.989z" />
                        <path d="M57.596 38.781l4.235-1.376 4.114-8.073-2.814-1.433-3.623-1.845-5.542 10.877zM56.869 47.145l-1.376-4.235-3.631-1.85-5.535 10.864 3.619 1.846 2.816 1.434zM65.118 47.814l-4.062 1.319-4.165 8.175 4.746 2.418 1.944.991 5.537-10.865zM65.901 39.624l1.32 4.061 3.999 2.038 5.541-10.878-1.94-.99-4.747-2.417z" />
                      </g>
                    </Link>
                  </g>
                  <g
                    id="home"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    ref={el => this.shapesRef.push(el)}
                  >
                    <Link
                      to={`${langPrefix}/`}
                      data-target="home"
                      onClick={() => {
                        this.handleToggleClick();
                      }}
                      className={`${pathName === '' ? 'active' : ''}`}
                    >
                      <g id="home-normal" stroke="#FDFAF6">
                        <path d="M11.5 8.216c-.621-.317-1.415-.008-1.771.691L3.156 21.809c-.354.697-.14 1.521.483 1.837l14.409 8.532c.632.323 1.469-.052 1.868-.837l.65-1.276c-.121.235 0 .535.264.67.266.135.578.055.697-.181l.022.012c-.12.235-.001.535.265.67s.578.055.698-.18l.021.01c-.12.236-.002.535.265.671.265.135.577.054.697-.181l.021.011c-.121.235-.002.535.266.67.265.136.578.055.697-.182l.021.012c-.12.235-.001.537.265.67.267.136.578.056.699-.179l.021.009c-.12.236-.003.537.264.67.266.136.577.057.697-.179l6.077-11.925c.119-.235.001-.536-.264-.67s-.579-.055-.697.181l-.022-.012c.12-.236.001-.535-.266-.67-.266-.136-.579-.056-.698.179l-.021-.009c.121-.235.002-.538-.264-.671-.266-.136-.579-.056-.698.179l-.022-.01c.12-.234.003-.534-.264-.67-.267-.134-.579-.055-.697.181l-.021-.011c.119-.236.001-.535-.266-.671-.266-.135-.577-.054-.697.182l-.022-.012c.119-.235.004-.535-.263-.67-.265-.135-.578-.055-.697.181l.649-1.279c.4-.783.211-1.681-.42-2.003L11.5 8.216z" />
                        <path
                          id="neck-normal"
                          transform="rotate(117 41.252 33.083)"
                          d="M32.408 26.453h17.686v13.261H32.408z"
                        />
                      </g>
                      <g id="home-hover" stroke="#50E3C2">
                        <path d="M11.5 8.216c-.621-.317-1.415-.008-1.771.691L3.156 21.809c-.354.697-.139 1.52.483 1.838l14.409 8.531c.632.322 1.469-.053 1.868-.835l.65-1.277c-.12.234 0 .535.265.669.265.137.576.055.696-.18l.022.012c-.12.235-.001.535.265.67.266.134.577.055.698-.18l.021.01c-.12.237-.002.535.265.672.266.135.577.054.697-.182l.021.012c-.12.234-.001.535.267.67.266.136.577.056.697-.181l.021.011c-.12.236-.002.537.264.671.268.135.579.055.699-.18l.021.01c-.119.234-.002.535.265.669.266.136.577.057.697-.18l6.076-11.925c.12-.235.001-.536-.264-.671-.265-.134-.578-.054-.696.18l-.022-.01c.12-.236.001-.535-.266-.67-.266-.137-.578-.057-.698.179l-.021-.009c.12-.236.002-.538-.265-.672-.265-.135-.578-.056-.697.179l-.022-.01c.12-.235.003-.534-.264-.671-.267-.133-.578-.054-.698.182l-.021-.011c.118-.237.001-.535-.266-.671-.266-.135-.577-.054-.697.181l-.022-.012c.119-.234.003-.534-.262-.67-.266-.136-.579-.054-.698.182l.649-1.28c.4-.783.211-1.681-.42-2.003L11.5 8.216z" />
                        <path
                          transform="rotate(117 41.285 33.077)"
                          d="M32.442 26.446h17.686v13.262H32.442z"
                        />
                        <path
                          transform="rotate(-63.004 41.268 33.054)"
                          d="M38.95 26.395h4.634V39.71H38.95z"
                        />
                      </g>
                    </Link>
                  </g>
                </svg>
              </div>
              <div className="main-menu__screen" style={menuScreenStyle} />
            </div>
            <ul className="main-menu__list">
              <li>
                <Link
                  onMouseEnter={e => this.handleEnter(e)}
                  onMouseLeave={e => this.handleLeave(e)}
                  data-target="expertise"
                  onClick={() => {
                    this.handleToggleClick();
                  }}
                  className={`${liHover === 'expertise' ? 'active' : ''} ${
                    pathName.includes('expertise') ? 'active' : ''
                  }`}
                  to={`${langPrefix}/expertise/`}
                >
                  Expertise
                </Link>
              </li>
              <li>
                <Link
                  onMouseEnter={e => this.handleEnter(e)}
                  onMouseLeave={e => this.handleLeave(e)}
                  data-target="services"
                  onClick={() => {
                    this.handleToggleClick();
                  }}
                  className={`${liHover === 'services' ? 'active' : ''} ${
                    pathName.includes('services') ? 'active' : ''
                  }`}
                  to={`${langPrefix}/services/`}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  onMouseEnter={e => this.handleEnter(e)}
                  onMouseLeave={e => this.handleLeave(e)}
                  data-target="process"
                  onClick={() => {
                    this.handleToggleClick();
                  }}
                  className={`${liHover === 'process' ? 'active' : ''} ${
                    pathName.includes('process') ? 'active' : ''
                  }`}
                  to={`${langPrefix}/process/`}
                >
                  <Trans>Process</Trans>
                </Link>
              </li>
              <li>
                <Link
                  onMouseEnter={e => this.handleEnter(e)}
                  onMouseLeave={e => this.handleLeave(e)}
                  data-target="lab"
                  onClick={() => {
                    this.handleToggleClick();
                  }}
                  className={`${liHover === 'lab' ? 'active' : ''} ${
                    pathName.includes('lab') ? 'active' : ''
                  }`}
                  to={`${langPrefix}/lab/`}
                >
                  Lab
                </Link>
              </li>
              <li>
                <Link
                  onMouseEnter={e => this.handleEnter(e)}
                  onMouseLeave={e => this.handleLeave(e)}
                  data-target="contact"
                  onClick={() => {
                    this.handleToggleClick();
                  }}
                  className={`${liHover === 'contact' ? 'active' : ''} ${
                    pathName.includes('contact') ? 'active' : ''
                  }`}
                  to={`${langPrefix}/contact/`}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

export default withI18n()(NavPanel);
