import React from "react";

const LogoLumen = props => (
  <svg width={101} height={35} viewBox="0 0 101 35" {...props}>
    <g id="Page-1" fill="none" fillRule="evenodd">
      <g id="Home-Alt" transform="translate(-669 -2489)" fill="#FFF">
        <g id="Group" transform="translate(669 2489)">
          <path
            d="M76.185 25.959c.244.384.244.96.244 1.326v4.137c0 .384 0 1.571-.261 2.165-.07.157-.192.279-.192.314 0 .017.157.035.349.035h1.815c.192 0 .35-.018.35-.035 0-.035-.123-.157-.193-.314-.262-.611-.244-1.78-.244-2.165v-4.137c0-.174.157-.314.349-.314h3.16c.854 0 1.465.42 1.465 1.082 0 .699-.61 1.118-1.466 1.118H80.34c-.157 0-.296 0-.453-.035-.175-.018-.332-.105-.35-.105-.035 0-.104.14-.192.28l-.54.907c-.07.157-.018.297.174.297h1.1c.174 0 .401.122.506.261l1.414 2.008c.331.454.925 1.274 2.234 1.274.192 0 .401-.018.646-.052.174-.035.331-.088.331-.105 0-.035-.122-.14-.262-.262-.715-.628-1.518-1.815-1.815-2.217l-.454-.646c-.104-.14-.052-.279.122-.331 1.187-.332 1.833-1.205 1.833-2.34 0-1.605-1.257-2.495-3.054-2.495h-5.254c-.192 0-.35.017-.35.035 0 .035.123.14.21.314"
            id="Fill-1"
          />
          <path
            d="M79.415 3.733c.228 3.786.298 10.112 0 13.846-.087.998-.314 1.875-.664 2.61-.088.159-.018.299.175.299h5.142c.175 0 .28-.158.245-.333-.682-3.575-.437-9.096-.612-13.6 0-.176-.087-.631.088-.579.174.053.28.368.367.509 3.428 4.574 6.734 9.253 10.25 13.74.105.14.35.263.543.263h3.06a.344.344 0 0 0 .333-.333c.105-4.942-.63-12.671-.14-17.666.07-.701.298-1.56.612-2.086.105-.158.21-.298.21-.333 0-.035-.14-.07-.314-.07h-5.283c-.193 0-.298.14-.245.315.822 3.26.437 8.921.717 12.777 0 .175-.087.473-.227.35-.298-.245-.787-.981-.997-1.261C89.893 8.412 86.902 3.943 83.91.245A.814.814 0 0 0 83.386 0h-4.6c-.193 0-.298.14-.228.315.333.86.77 1.858.857 3.418"
            id="Fill-4"
          />
          <path
            d="M64.037 8.895c0 4.157.335 8.63-.723 11.502-.07.176.035.317.212.3 3.648-.053 7.58.176 11.034-.089.177-.017.424-.14.512-.317.493-.863.493-2.237.81-3.311.071-.159-.035-.264-.211-.23-1.957.353-4.442.177-6.734.212-.158 0-.317-.158-.317-.334v-4.633c0-.176.159-.317.317-.3 1.393.036 2.997-.14 4.23.054.177.035.318-.089.318-.265v-3.24c0-.177-.14-.3-.317-.282-1.287.158-2.803.088-4.23.088a.328.328 0 0 1-.318-.317V4.21c0-.176.159-.317.317-.317 1.886.07 4.09-.212 5.641.176.159.053.3-.053.3-.247A36.971 36.971 0 0 0 74.648.6a.45.45 0 0 0-.37-.37c-2.961-.317-6.698.123-9.642-.194a8.53 8.53 0 0 0-.67-.035h-.581c-.194 0-.265.106-.177.282 1.058 2.096.829 5.372.829 8.613"
            id="Fill-6"
          />
          <path
            d="M96.304 32.19c-1.083.314-2.898.367-3.753.367h-1.397c-.192 0-.296-.122-.296-.297v-1.588c0-.192.104-.315.296-.315h1.519c.489 0 1.082.018 1.431.158.175.07.332.157.35.157.034 0 .104-.123.191-.297l.646-1.1c.087-.157.018-.296-.157-.296h-3.98c-.192 0-.296-.123-.296-.315v-1.379c0-.174.104-.331.296-.331h2.025c1.012 0 2.042.105 2.636.384.157.07.297.122.314.122.017 0 0-.105-.052-.28l-.35-1.256a.47.47 0 0 0-.418-.314h-6.18c-.191 0-.349.017-.349.035 0 .035.123.157.21.314.21.384.21.942.21 1.326v4.137c0 .384 0 1.554-.245 2.147-.07.175-.175.297-.175.332 0 .017.158.035.35.035h6.179a.597.597 0 0 0 .488-.297l.75-1.257c.088-.157.123-.296.123-.296s-.192.052-.366.104"
            id="Fill-8"
          />
          <path
            d="M66.644 30.13l1.082-2.39c.07-.158.192-.158.262 0l1.117 2.39c.07.175-.017.315-.192.315h-2.077c-.174 0-.262-.14-.192-.314zm-1.536 3.806c.175 0 .332-.018.332-.035 0-.035-.087-.175-.105-.367-.017-.366.227-.855.367-1.222l.14-.296c.07-.14.279-.28.453-.28h3.282c.174 0 .366.14.453.28.07.157.14.296.14.296.175.367.402.856.384 1.222-.017.192-.105.332-.105.367 0 .017.14.035.315.035h1.78c.175 0 .314-.018.314-.035 0-.035-.087-.157-.192-.297-.436-.593-.995-1.798-1.17-2.182l-2.146-4.59c-.157-.315-.245-.611-.262-.82-.035-.175.017-.332.017-.367 0-.018-.14-.035-.331-.035H66.94c-.192 0-.332.017-.332.035 0 .035.105.157.14.349.07.384-.175.907-.35 1.291l-1.832 4.137c-.174.384-.716 1.606-1.187 2.2-.087.14-.21.244-.21.279 0 .017.14.035.315.035h1.623z"
            id="Fill-11"
          />
          <path
            d="M4.184 28.21c0-.698.75-1.256 2.549-1.256 1.222 0 2.304.349 2.845.89.14.105.227.227.262.227.017 0 .104-.14.174-.28l.454-.872c.087-.157.017-.35-.157-.437-.611-.279-1.85-.872-3.508-.872-2.217 0-4.242.785-4.242 2.723 0 3.787 6.633 1.012 6.633 3.246 0 .629-.698 1.24-2.793 1.24-1.292 0-2.374-.332-2.915-1.013-.105-.157-.174-.279-.21-.279-.017 0-.104.122-.174.297l-.489.995c-.087.14-.017.366.123.453.523.332 1.623.89 3.787.89 2.933 0 4.381-1.186 4.381-2.687 0-4.137-6.72-1.397-6.72-3.264"
            id="Fill-14"
          />
          <path
            d="M46.438 25.645l-.28.035a7.212 7.212 0 0 1-.645.034h-4.905c-.21 0-.471-.017-.68-.034l-.315-.035a10.744 10.744 0 0 0-.35-.035c-.034 0-.104.122-.156.296l-.611 1.345c-.053.174-.105.314-.07.314.017 0 .14-.07.332-.157.418-.21.942-.332 1.5-.332h1.013c.192 0 .35.14.35.314v4.137c0 .384 0 1.57-.315 2.164-.07.175-.192.28-.192.315 0 .017.157.034.35.034h1.902c.174 0 .331-.017.331-.034 0-.035-.104-.158-.192-.315-.296-.593-.296-1.78-.296-2.164V27.39c0-.174.157-.314.331-.314h1.117c.402 0 .681.07.82.227.158.105.21.262.228.262.052 0 .14-.14.227-.297l.768-1.379c.087-.157.122-.28.104-.28l-.366.036"
            id="Fill-17"
          />
          <path
            d="M19.277 32.819c-1.745 0-3.142-1.03-3.142-2.933 0-1.902 1.397-2.932 3.142-2.932 1.728 0 3.142 1.03 3.142 2.932 0 1.903-1.414 2.933-3.142 2.933m0-7.21c-2.635 0-4.765 1.52-4.765 4.277 0 2.758 2.13 4.277 4.765 4.277 2.636 0 4.766-1.519 4.766-4.277s-2.13-4.276-4.766-4.276"
            id="Fill-20"
          />
          <path
            d="M24.91 21.253c1.487-.16 2.274-.623 3.585-1.264.158-.09.368-.018.455.142.175.32.332.677.63.855.157.106.437.089.612.035a56.975 56.975 0 0 1 3.794-.836c.175-.036.21-.16.088-.303-1.574-1.887-1.154-5.839-1.154-10.306 0-3.988-.368-7.263.664-9.257.088-.178.018-.284-.157-.284-1.924.053-4.11-.16-5.841.089-.175.017-.227.16-.14.32.385.623.577 1.46.647 2.26.35 3.792-.245 8.794-.017 12.87 0 .178-.105.428-.263.517-1.241.836-2.553 1.388-4.564 1.228-6.838-.588-3.62-12.514-1.784-16.982.07-.178-.017-.302-.192-.302h-5.281c-.193 0-.333.089-.333.195 0 .125.035.356.053.552.262 1.887-.472 4.094-.735 6.124-.297 2.225-.577 4.61-.42 6.603.403 5.358 4.075 8.402 10.353 7.744"
            id="Fill-23"
          />
          <path
            d="M28.17 25.645c0 .035.105.14.21.314.227.384.227.942.227 1.326v4.137c0 .384 0 1.571-.262 2.165-.07.157-.174.279-.174.314 0 .017.14.035.331.035h1.816c.174 0 .314-.018.314-.035 0-.035-.105-.157-.175-.332-.227-.593-.227-1.763-.227-2.147v-.75c0-.192.14-.315.332-.315h1.257c.489 0 1.082.018 1.449.158.174.07.314.157.331.157.018 0 .122-.123.21-.297l.628-1.1c.087-.157.017-.296-.157-.296h-3.718c-.192 0-.332-.123-.332-.315v-1.379c0-.174.14-.331.332-.331h2.252c.995 0 1.99.105 2.618.384.157.07.297.122.314.122.035 0 0-.105-.035-.28l-.366-1.256a.47.47 0 0 0-.42-.314h-6.423c-.192 0-.331.017-.331.035"
            id="Fill-25"
          />
          <path
            d="M37.526 4.434c.174 4.224.295 10.909-.14 13.91-.104.682-.382 1.258-.59 1.764-.053.174.052.314.226.314 1.686-.052 3.615.14 5.128-.087.174-.018.278-.192.226-.367-.835-3.264-.643-8.64-.73-12.585 0-.174.052-.192.121-.034 1.721 3.944 3.495 7.976 5.216 11.694.208.436.365 1.082.817 1.396.139.088.434.053.556-.07.365-.331.504-.925.695-1.326 1.86-3.857 3.703-7.837 5.615-11.555.087-.157.14-.14.14.035-.21 4.224.295 9.181-.713 12.585-.052.174.052.314.226.314h5.11c.192 0 .261-.122.192-.297-.574-1.274-.609-2.88-.696-4.66-.19-4.626-.4-11.747.557-14.47.104-.297.47-.925.139-.96h-4.207a.566.566 0 0 0-.47.297c-2.068 4.468-4.328 8.745-6.553 13.056-.087.14-.226.14-.296-.018-2.069-4.398-4.294-8.622-6.31-13.038a.566.566 0 0 0-.47-.297c-1.46.035-3.146-.14-4.432.07-.174.017-.226.157-.122.314.626.943.695 2.531.765 4.015"
            id="Fill-28"
          />
          <path
            d="M58.763 25.645c0 .035.105.174.14.349.052.401-.087.925-.192 1.291l-.978 3.77c-.052.175-.07.315-.07.315s-.052-.14-.104-.314l-1.082-3.77c-.105-.367-.262-.89-.21-1.275.018-.192.088-.331.088-.366 0-.018-.14-.035-.297-.035H54.4c-.175 0-.332.017-.332.035 0 .035.087.174.122.366.052.384-.105.908-.192 1.274l-1.065 3.7c-.052.175-.104.315-.104.315-.018 0-.053-.14-.105-.314l-.925-3.7c-.087-.367-.245-.89-.175-1.275.018-.192.105-.331.105-.366 0-.018-.157-.035-.35-.035h-1.553c-.157 0-.314.017-.314.035 0 .035.087.174.157.331.21.402.402.943.506 1.31l1.31 5.026c.104.35.244.873.157 1.24-.053.174-.157.314-.157.349 0 .017.157.035.349.035h1.728c.192 0 .331-.018.331-.035 0-.035-.087-.175-.104-.35-.07-.383.087-.89.174-1.239l1.065-3.84c.052-.174.105-.296.105-.296s.07.122.104.296l1.083 3.84c.104.332.244.856.192 1.222-.018.192-.105.332-.105.367 0 .017.157.035.349.035h1.728c.175 0 .332-.018.332-.035 0-.035-.105-.157-.157-.35-.088-.366.034-.89.157-1.239l1.344-5.027c.104-.366.296-.925.506-1.309.087-.174.174-.296.174-.331 0-.018-.14-.035-.314-.035h-1.414c-.192 0-.349.017-.349.035"
            id="Fill-30"
          />
          <path
            d="M.97 10.04c0 3.905.264 7.496-.723 10.024-.053.157.035.296.211.296 3.737-.07 7.792.174 11.317-.105a.722.722 0 0 0 .494-.313c.423-.942.423-2.301.723-3.382.035-.192-.053-.279-.23-.244-1.903.436-4.389.331-6.733.331a.295.295 0 0 1-.3-.331c.282-5.282-.776-11.888.687-16.02C6.47.14 6.4 0 6.205 0H.317C.141 0 0 .017 0 .07c0 .035.07.174.176.348 1.11 2.005.794 5.805.794 9.623"
            id="Fill-33"
          />
          <path
            d="M99.723 32.179h-.35c-.017 0-.052-.035-.052-.07v-.227c0-.035.035-.07.052-.07h.262c.14 0 .332 0 .332.175 0 .157-.087.192-.244.192m.523-.175c0-.366-.21-.419-.54-.419h-.612c-.035 0-.07.018-.07.053v1.291c0 .035.035.053.07.053h.175c.035 0 .052-.018.052-.053v-.436c0-.035.035-.07.052-.07h.123c.035 0 .087.035.087.053l.28.453c.017.035.069.053.104.053h.175c.034 0 .052-.018.034-.053l-.261-.453c-.018-.018-.018-.053.017-.053.21-.035.314-.174.314-.419"
            id="Fill-35"
          />
          <path
            d="M99.515 34.012a1.062 1.062 0 0 1-1.065-1.083c0-.593.489-1.047 1.065-1.047s1.065.454 1.065 1.047c0 .629-.49 1.083-1.065 1.083m0-2.427c-.75 0-1.344.611-1.344 1.344 0 .768.593 1.38 1.344 1.38.75 0 1.344-.612 1.344-1.38 0-.733-.594-1.344-1.344-1.344"
            id="Fill-38"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default LogoLumen;
